/* Element Layer */

body {
  width: 100%;
  height: 100%;
  font-family: Poppins, sans-serif;
  font-size: 1.1em;
  font-weight: normal;
  line-height: 1.6;
  margin: 0;
  color: #363636;
  background: #f4f4f4;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* for react-error-boundly */

body > Iframe {
  display: None !important;
}

/* base styles */

ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
